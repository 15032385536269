/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { startTransition, StrictMode } from "react";
import { hydrateRoot } from "react-dom/client";
import { RootLoaderDataSchema } from "./utils";

const rootLoader = RootLoaderDataSchema.safeParse(window.__remixContext.state.loaderData?.root);

Sentry.init({
  dsn: rootLoader.data?.sentryDSN,
  release: rootLoader.data?.sentryRelease,
  environment: rootLoader.data?.sentryEnvironment,
  integrations: [],
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 1.0,
});

import("@sentry/remix").then((lazyLoaded) => {
  Sentry.addIntegration(lazyLoaded.replayIntegration());
  Sentry.addIntegration(lazyLoaded.extraErrorDataIntegration());
});

Sentry.setUser({
  id: rootLoader.data?.sentryUserId,
  username: rootLoader.data?.sentryUsername,
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
